<template>
  <section class="mainText ">
    <b-container fluid="xl">
        <b-row class="maintText__row">
        <b-col cols="12" class="mainText__mainName side-gap">

          <h2 style="mainText__mainName--maintxtx">
            A deepfake tartalmak veszélyei
          </h2>
          <p class="hashText">- Mi történik, ha ellopják az arcod vagy a hangod?</p>
        </b-col>
      </b-row>
        <b-row class="maintText__row">
            <b-col cols="12" lg="6">
            <div class="maintText__txt-content pb-lg-5 pb-2">
                <p class="text">
                A technológia fejlődése lehetővé teszi számunkra, hogy élvezhessük a mesterséges intelligencia alkotta új és kreatív tartalmak világát. Azonban a fejlődés adta lehetőségeket nem mindenki használja jó célokra. </p>

                <p class="text">
                A technológia fejlődése gyakran olyan előnyöket hoz magával, amelyek lehetővé teszik számunkra, hogy élvezhessük például a mesterséges intelligencia alkotta új és kreatív tartalmak világát. Azonban a fejlődés adta lehetőségeket nem mindenki használja jó célokra. A deepfake technológia lehetővé teszi, hogy manipulált videók és hangfelvételek készüljenek olyan módon, hogy valósághűnek tűnjenek. Ez a jelenség komoly következményekkel járhat, ha valaki ellopja az arcodat vagy a hangodat.<br>Íme néhány mód, ahogyan ez árthat neked, valamint néhány tanács, hogyan védekezhetsz ellene.</p>
            </div>
            </b-col>
            <b-col cols="12" lg="6" class="order-4 order-lg-1">
              <img src="images/deepfake.png" class="w-100 margin-top-sm-44 mb-4"/>
          </b-col>
            <b-col cols="12" lg="10" class="offset-lg-1 side-gap order-lg-2 order-1">
            <p class="text">
              <ul>
                <li class="py-3 d-sm-flex">
                  <span class="number">1</span>
                  <p class="text"><b>Becsület csorbítása, virtuális ál-rágalom:</b> Ha valaki rossz szándékkal használja a deepfake technológiát, képes lehet manipulált videókat készíteni, amelyeken az arcod látható egy olyan helyzetben, amiben soha nem voltál, legyen szó kellemetlen, degradáló vagy akár illegális szituációkról. Ezek a videók akár vírusszerűen is terjedhetnek, és súlyos kárt okozhatnak a kapcsolataidban, az emberek rólad alkotott képében és személyes jó híredben.</p> 
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">2</span>
                  <p class="text"><b>Identitásszivárgás:</b>Az arclopás (azaz arcodról készült képek felhasználása) és a hanglopás lehetőséget ad arra, hogy valaki másnak tűnj az interneten vagy akár a való életben. Különösen éles eszköz lehet ez a csalók kezében, hogy hamis identitásokat létrehozva olyan tevékenységeket végezzenek, amelyekért te kerülhetsz felelősségre vonásra, ráadásul így még könnyebben eltűnhetnek és rejtőzködhetnek bűntettük után.</p>
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">3</span>
                  <p class="text"><b>Zaklatás: </b> A deepfake technológia lehetővé teszi a zaklatók számára, hogy manipulált videókat készítsenek, amelyeken te vagy látható, vagy hallható. Ezeket a videókat aztán felhasználhatják arra, hogy zaklassanak, zsaroljanak vagy lejárassanak téged.</p>
                </li>
                <b>Hogyan védekezhetsz a deepfake tartalmak ellen?</b>
                <li class="py-3 d-sm-flex">
                  <span class="number">1</span>
                  <p class="text"><b>Tartsd biztonságban az adataidat: </b> Fontos, hogy vigyázz az olyan személyes információkkal, mint például a fényképek, a hangfelvételek vagy a videók. Ne oszd meg ezeket a tartalmakat, ismeretlenekkel, vagy legalábbis igyekezz minimalizálni ezek megjelenését! Továbbá gondoskodj arról, hogy jelszóval védett és biztonságos helyen tárolod őket!</p> 
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">2</span>
                  <p class="text"><b>Légy óvatos azzal, hogy milyen tartalmakat osztasz meg az interneten: </b>Gondold át alaposan, mielőtt bármilyen személyes tartalmat megosztasz a közösségi médián! Legyél körültekintő azzal kapcsolatban is, hogy milyen fotókat, videókat vagy hangfelvételeket teszel közzé a közösségi média platformokon vagy más online fórumokon! Mindig ellenőrizd, hogy milyen következményekkel járhat a tartalom nyilvánosságra hozása, és milyen módon használható fel a deepfake manipulációban!</p>
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">3</span>
                  <p class="text"><b>Keresd az adatvédelmi beállításokat: </b> Használj erős jelszavakat és kétlépcsős azonosítási folyamatot az online fiókjaidhoz! Rendszeresen ellenőrizd és frissítsd az adatvédelmi beállításaidat a közösségi média platformokon és az egyéb online szolgáltatásokon, hogy minimalizáld az esélyét annak, hogy adataid illetéktelen kezekbe kerüljenek!</p>
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">4</span>
                  <p class="text"><b>Képzeld el a lehetséges káros szituációkat: </b> Legyen egyértelmű elképzelésed arról, hogyan lehetne felhasználni az arcodat vagy a hangodat deepfake célokra! Ha tudod, hogyan nézhetnek ki ezek a manipulált tartalmak, könnyebben felismerheted őket, ha valaha is találkozol velük az interneten vagy más médiumokban.</p>
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">5</span>
                  <p class="text"><b>Edukáld magad, ismerd fel a jeleket és jelentsd az esetleges deepfake tartalmakat: </b>Tanuld meg felismerni a deepfake jellegzetességeit, például a torzított arcmozgást, a valószerűtlen háttérkörnyezetet vagy a hanghullámok nem megfelelő illeszkedését! Ha találsz gyanús tartalmakat, jelentsd az adott platformnak, hogy leszedjék azokat vagy intézkedjenek ellene.</p>
                </li>
              </ul>
            </p>
            <p class="text">Ha ez még mindig disztópikusnak vagy lehetetlennek hathat, összefoglalásul bemutatjuk, hogy is nézhet ki egy deepfake videóval való visszaélés. 
                Képzelj el egy olyan helyzetet, ahol egy jól ismert politikus vagy híresség vagy! Egy nap arra ébredsz, hogy az interneten terjednek manipulált videók, amelyeken a te arcod látható és a hangod hallható, de olyan szituációhoz illesztve, melyben soha nem is voltál. Ezek a videók hamar elterjednek a közösségi média platformokon, és sok ember azt hiszi, hogy valódiak. A jó hírneved sérül, és nehéz lehet visszaállítani az emberek bizalmát, ami hatással lehet nem csupán a kapcsolataidra, de a munkalehetőségeidre, ráadásul komoly pszichés terhet jelenthet a videót valósnak hívő emberek folyamatos zaklatása, negatív véleményének hangoztatása kommenteken vagy reakcióvideók tömegén keresztül. 
            </p>
            <p class="text">Nem kell ahhoz híresnek lenni, hogy rosszul érintsen egy ehhez hasonló manipulált tartalom elterjedése. Amennyiben észleled, hogy gyanús tartalmak terjednek rólad, lépj azonnal közbe. Gyűjts bizonyítékokat, például képernyőképeket vagy linkeket a hamis tartalmakhoz, és jelentsd az esetet a közösségi média platformoknak vagy más érintett szervezeteknek, például a rendőrségnek! Az ilyen jelentések segítenek a tartalom gyorsabb eltávolításában és a szükséges intézkedések megtételében.</p>
        </b-col>
        </b-row>
    </b-container>
    <Articles :isFirst="false"/>
  </section>
</template>
<script>
import Articles from '../components/Articles.vue'

export default {
  components: {
    Articles
  },
  mounted(){

    this.setMetaTags({        
        title: "A deepfake tartalmak veszélyei", 
        desc: "A technológia fejlődése lehetővé teszi számunkra, hogy élvezhessük a mesterséges intelligencia alkotta új és kreatív tartalmak világát.", 
        keywords: null, 
        ogType: 'website', 
        ogTitle: "A deepfake tartalmak veszélyei",
        ogImage: null, 
        ogImageWidth: null, 
        ogImageHeight: null, 
        ogDesc: "A technológia fejlődése lehetővé teszi számunkra, hogy élvezhessük a mesterséges intelligencia alkotta új és kreatív tartalmak világát."        
    })
  }
}
</script>